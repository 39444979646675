import { useState, useRef, useEffect } from "react";
import { Box, Link, CloseButton } from "@chakra-ui/react";
import type { AutocompletedResult } from "@elastic/search-ui";
interface IProps {
	autocompletedResults: AutocompletedResult[];
	getItemProps: ({}) => any;
}

const SiteSearchAutocomplete = ({
	autocompletedResults,
	getItemProps,
}: IProps) => {
	const [closeAutocomplete, setCloseAutocomplete] = useState(false);
	const [maxWidth, setMaxWidth] = useState(null);

	const ref = useRef(null);

	useEffect(() => {
		if (ref.current) {
			setMaxWidth(ref.current.parentElement.clientWidth);
		}
	}, [ref]);

	if (closeAutocomplete) return null;
	return (
		<Box
			className="sui-search-box__autocomplete-container"
			ref={ref}
			position="absolute"
			left={{ base: "20px", sm: "auto" }}
			width="100%"
			maxWidth={{ base: "calc(100% - 40px)", sm: `${maxWidth}px` }}
			paddingRight={{ base: "20px", smg: "0" }}
			background="#FFFFFF"
			pointerEvents="all"
			mt="1px"
			zIndex="50"
		>
			<CloseButton
				onClick={() => {
					setCloseAutocomplete(true);
				}}
				size="lg"
				position="absolute"
				right="2"
				top="-46px"
				display={{ base: "none", sm: "block" }}
				_hover={{ background: "rgba(0,0,0,0.01)" }}
				_active={{ background: "none" }}
			/>
			{autocompletedResults.map((result, i) => {
				let title = result.title.raw;
				if (!title) {
					title = result.sections.raw[0];
				}
				return (
					<Link
						href={result.url.raw}
						key={i}
						display="block"
						color="#6863F2"
						py="4"
						_hover={{ background: "rgba(0,0,0,0.01)" }}
						borderBottom={
							i !== autocompletedResults.length - 1 ? "1px solid #CECEDC" : null
						}
					>
						<Box px="4">{title}</Box>
						<Box px="4" fontSize="12px" color="rgba(0,0,0,0.5)">
							{result.sections.raw[0]}
						</Box>
					</Link>
				);
			})}
		</Box>
	);
};

export default SiteSearchAutocomplete;
