import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import clsx from "clsx";
import parse from "html-react-parser";
import { useAtom } from "jotai";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

import { isSiteMobileMenuActiveAtom } from "@atoms/siteHeader";
import { CaretDown } from "@components/Icons";
import PrimaryButton from "@components/PrimaryButton";
import { Disclosure, Transition } from "@headlessui/react";
import { headerHeight } from "@lib/headerHeight";
import { getHref } from "@lib/routes";

const SiteMobileMenu = ({ items, callToActions, setShowMobileMenu }) => {
	const router = useRouter();

	const scrollTargetRef = useRef<HTMLDivElement>(null);

	const [isScrollTargetRefVisible, setIsScrollTargetRefVisible] =
		useState(false);

	const [isSiteMobileMenuActive, setIsSiteMobileMenuActive] = useAtom(
		isSiteMobileMenuActiveAtom
	);

	const handleClose = async (event) => {
		event.preventDefault();
		setIsSiteMobileMenuActive(false);
		setShowMobileMenu(false);
		await router.push(event.target.href);
	};

	/*
		Close menu when route changes
	*/
	useEffect(() => {
		const routeChangeStart = () => {
			setIsSiteMobileMenuActive(false);
			setShowMobileMenu(false);
		};
		router.events.on("routeChangeStart", routeChangeStart);
		router.events.on("routeChangeError", routeChangeStart);
	}, [router, setIsSiteMobileMenuActive]);

	useEffect(() => {
		const targetElement = scrollTargetRef.current;

		if (!isScrollTargetRefVisible) return;

		if (isSiteMobileMenuActive) disableBodyScroll(targetElement);
		if (!isSiteMobileMenuActive) enableBodyScroll(targetElement);
	}, [isSiteMobileMenuActive, isScrollTargetRefVisible]);

	const parentItems = items?.filter((i) => i.level === 1) || [];
	const navItems = parentItems.length > 0 ? parentItems : items;

	return (
		<Transition
			show={isSiteMobileMenuActive}
			unmount={true}
			appear={true}
			beforeEnter={() => {
				setShowMobileMenu(true);
			}}
			afterLeave={() => {
				setShowMobileMenu(false);
			}}
			className={clsx("SiteMobileMenu", "fixed inset-0 z-40")}
		>
			<Transition.Child
				className={clsx(
					"SiteMobileMenu__content",
					"bg-black-01",
					"pb-15 pt-20",
					"flex flex-col",
					"absolute left-0 top-0 bottom-0 right-0 md:left-auto",
					"md:w-1/2"
				)}
				enter="transition-all duration-500 ease-out"
				enterFrom="opacity-0 translate-x-full"
				enterTo="opacity-100 translate-x-0"
				leave="transition-all duration-500 ease-out"
				leaveFrom="opacity-100 translate-x-0"
				leaveTo="opacity-0 translate-x-full"
				style={{ paddingTop: headerHeight }}
			>
				<div
					className={clsx(
						"SiteMobileMenu__items",
						"h-full",
						"overflow-y-scroll no-scrollbar",
						"w-full max-w-md bg-white",
						"p-7.5 pt-20 md:p-15 pb-30"
					)}
					ref={(el) => {
						scrollTargetRef.current = el;
						setIsScrollTargetRefVisible(!!el);
					}}
				>
					{navItems &&
						navItems.map((item, index) => {
							const href = item.target.element
								? getHref(item.target.element)
								: item.target.url;
							const target = item.target.type === "url" ? "_blank" : null;

							const childItems =
								items?.filter((i) => i.parent?.id === item.id) || [];

							return (
								<Disclosure key={index}>
									{({ open }) => (
										<>
											<div
												className={clsx("flex items-center", {
													"mb-3": childItems.length == 0 || !open,
												})}
											>
												{!!href && (
													<Link
														href={!!href ? href : ""}
														className={clsx(
															"text-white-01",
															"text-2xl md:text-3xl"
														)}
														target={target}
														onClick={handleClose}
													>
														{item.title ? item.title : item.target.text}
													</Link>
												)}
												{!href && (
													<div className="navItem__container">
														{childItems.length > 0 && (
															<Disclosure.Button
																className={clsx(
																	"text-white-01",
																	"flex items-center justify-center"
																)}
															>
																<a
																	className={clsx(
																		"text-white-01",
																		"text-2xl md:text-3xl"
																	)}
																	target={target}
																>
																	{item.title}
																</a>
																<div
																	className={clsx("ml-3", {
																		"rotate-180": open,
																	})}
																>
																	<CaretDown />
																</div>
															</Disclosure.Button>
														)}
													</div>
												)}
											</div>
											<Transition
												enter="transition ease-out duration-500"
												enterFrom="transform opacity-0 translate-x-14"
												enterTo="transform opacity-100 translate-x-0"
												leave="transition ease-out duration-300"
												leaveFrom="transform opacity-100 translate-x-0"
												leaveTo="transform opacity-0 translate-x-14"
											>
												<Disclosure.Panel>
													{item.overviewLink?.url && (
														<Link
															href={item.overviewLink.url}
															className={clsx(
																"text-white-01",
																"pt-4",
																"px-0",
																"block"
															)}
															onClick={handleClose}
														>
															{item.overviewLink.text}
														</Link>
													)}
													{!!childItems && (
														<div className={clsx("flex flex-col pt-4")}>
															{childItems.map((childItem, index) => {
																const subChildItems =
																	items?.filter(
																		(i) => i.parent?.id === childItem.id
																	) || [];
																return (
																	<div
																		key={index}
																		className="childItem__container mb-4"
																	>
																		{subChildItems.length > 0 && (
																			<a
																				className={clsx(
																					"text-blue-03",
																					"py-0",
                                          "px-0",
                                          "mb-1",
																					"block",
																					{
																						"mt-3": !item.overviewLink?.url,
																					}
																				)}
																			>
																				{parse(childItem.title)}
																			</a>
																		)}

																		{subChildItems.map(
                                      (subChildItem, index) => {
																				const href = subChildItem.target.url;
																				return subChildItem.target.url ===
																					"#heading" ? (
																					<span
																						className={clsx(
																							"text-blue-03",
																							"py-0",
                                              "px-0",
                                              "mb-1",
																							"block",
																							"mt-5"
																						)}
																					>
																						{parse(subChildItem.title)}
																					</span>
																				) : (
																					<Link
																						key={index}
																						href={!!href ? href : ""}
																						className={clsx(
																							"text-white-01",
																							"py-3",
																							"text-sm",
																							"px-5",
																							"block"
																						)}
																						onClick={handleClose}
																					>
																						{parse(subChildItem.title)}
																					</Link>
																				);
																			}
																		)}
																	</div>
																);
															})}
														</div>
													)}
												</Disclosure.Panel>
											</Transition>
										</>
									)}
								</Disclosure>
							);
						})}
				</div>

				{!!callToActions && (
					<div
						className={clsx(
							"SiteMobileMenu__callToActions",
							"w-full",
							"bg-black-01",
							"px-7.5 md:px-15 pt-7.5",
							"absolute bottom-0",
							"mt-auto"
						)}
					>
						{callToActions.map((callToAction, index) => {
							return (
								<div
									key={index}
									className={clsx(
										"SiteMobileMenu__callToAction",
										"block mb-7.5"
									)}
								>
									<PrimaryButton
										target={{
											element: callToAction.element,
											type: "entry",
											text: callToAction.title,
										}}
										isFull
									/>
								</div>
							);
						})}
					</div>
				)}
			</Transition.Child>
		</Transition>
	);
};

export default SiteMobileMenu;
