import PageImage from "../../../packages/components/PageImage";
import SVG from "react-inlinesvg";

const LandingPageLogo = ({ logo }) => {
	return logo.svgContent ? (
		<SVG src={logo.svgContent} />
	) : (
		<PageImage image={logo} maxWidth="167px" />
	);
};

export default LandingPageLogo;
