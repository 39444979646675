import { Button, Flex } from "@chakra-ui/react";
import Link from "next/link";
import { ArrowRight } from "../../apps/abnormal/components/Icons";

const ButtonBlock = ({
	eventDescription,
	target,
	verticalPadding = "none",
	buttonBackgroundColor = null,
	buttonTextColor = "#fff",
	horizontalAlignment = "center",
	inline = false,
	isLandingPage = false,
    leftArrow = false,
	...rest
}) => {
	const handleClick = (text, url) => {
		window.dataLayer?.push({
			event: "event",
			eventProps: {
				category: "Button Click",
				action: eventDescription ? eventDescription : text,
				label: url,
			},
		});
	};
	return (
		<Flex
			justify={
				horizontalAlignment === "left"
					? "start"
					: horizontalAlignment === "center"
					? { base: "start", md: "center" }
					: horizontalAlignment === "right"
					? "end"
					: null
			}
			grow={!inline && 1}
			w={!inline && "full"}
			my={verticalPadding !== "none" && ["sm", "sm", verticalPadding]}
			className="ButtonBlock"
			{...rest}
		>
			<Button
				bg={buttonBackgroundColor}
				color={buttonTextColor}
				href={target?.url}
				p={
					(!buttonBackgroundColor || buttonBackgroundColor === "transparent") &&
					0
				}
				as={target?.url && Link}
				onClick={target && (() => handleClick(target.text, target.url))}
				rightIcon={!isLandingPage && !leftArrow && <ArrowRight />}
                leftIcon={!isLandingPage && !!leftArrow && <ArrowRight />}
				target={target?.target}
				_hover={
					!buttonBackgroundColor || buttonBackgroundColor === "transparent"
						? {
								bg: "transparent",
								".chakra-button__icon": {
									transform: "translateX(.25rem)",
								},
						  }
						: null
				}
			>
				{target?.text}
			</Button>
		</Flex>
	);
};

export default ButtonBlock;
