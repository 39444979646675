const defaults = [{ top: "", bottom: "", left: "", right: "" }];

export const blockSpacing = (
	blockPadding = defaults,
	mobileBlockPadding = defaults,
	blockMargin = defaults,
	mobileBlockMargin = defaults
) => {
	return {
		pt: {
			base: mobileBlockPadding[0]?.top
				? mobileBlockPadding[0]?.top === "none"
					? 0
					: mobileBlockPadding[0]?.top
				: blockPadding[0]?.top || null,
			md:
				mobileBlockPadding[0]?.top && !blockPadding[0]?.top
					? 0
					: blockPadding[0]?.top || null,
		},
		pr: {
			base: mobileBlockPadding[0]?.right
				? mobileBlockPadding[0]?.right === "none"
					? 0
					: mobileBlockPadding[0]?.right
				: blockPadding[0]?.right || null,
			md:
				mobileBlockPadding[0]?.right && !blockPadding[0]?.right
					? 0
					: blockPadding[0]?.right || null,
		},
		pb: {
			base: mobileBlockPadding[0]?.bottom
				? mobileBlockPadding[0]?.bottom === "none"
					? 0
					: mobileBlockPadding[0]?.bottom
				: blockPadding[0]?.bottom || null,
			md:
				mobileBlockPadding[0]?.bottom && !blockPadding[0]?.bottom
					? 0
					: blockPadding[0]?.bottom || null,
		},
		pl: {
			base: mobileBlockPadding[0]?.left
				? mobileBlockPadding[0]?.left === "none"
					? 0
					: mobileBlockPadding[0]?.left
				: blockPadding[0]?.left || null,
			md:
				mobileBlockPadding[0]?.left && !blockPadding[0]?.left
					? 0
					: blockPadding[0]?.left || null,
		},
		mt: {
			base: mobileBlockMargin[0]?.top
				? mobileBlockMargin[0]?.top === "none"
					? 0
					: mobileBlockMargin[0]?.top
				: blockMargin[0]?.top || null,
			md:
				mobileBlockMargin[0]?.top && !blockMargin[0]?.top
					? 0
					: blockMargin[0]?.top || null,
		},
		mr: {
			base: mobileBlockMargin[0]?.right
				? mobileBlockMargin[0]?.right === "none"
					? 0
					: mobileBlockMargin[0]?.right
				: blockMargin[0]?.right || null,
			md:
				mobileBlockMargin[0]?.right && !blockMargin[0]?.right
					? 0
					: blockMargin[0]?.right || null,
		},
		mb: {
			base: mobileBlockMargin[0]?.bottom
				? mobileBlockMargin[0]?.bottom === "none"
					? 0
					: mobileBlockMargin[0]?.bottom
				: blockMargin[0]?.bottom || null,
			md:
				mobileBlockMargin[0]?.bottom && !blockMargin[0]?.bottom
					? 0
					: blockMargin[0]?.bottom || null,
		},
		ml: {
			base: mobileBlockMargin[0]?.left
				? mobileBlockMargin[0]?.left === "none"
					? 0
					: mobileBlockMargin[0]?.left
				: blockMargin[0]?.left || null,
			md:
				mobileBlockMargin[0]?.left && !blockMargin[0]?.left
					? 0
					: blockMargin[0]?.left || null,
		},
	};
};
