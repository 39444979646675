import { getHref } from "lib/routes";
import Link from "next/link";
import { useRouter } from "next/router";

const Target = ({
  className = "",
  target,
  children,
  style = {},
  baseUrl = "",
  download = false,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
  handleClick = () => null,
}): JSX.Element => {
  const returnElement = () => {
    return (
      <div className={className} style={style}>
        {children}
      </div>
    );
  };

  const router = useRouter();
  //console.log(router.pathname);

  if (!target || target.type === "") {
    return returnElement();
  }

  // First, we're checking if the 'target' object has a 'type' property with a value of "entry".
  if (target.type === "entry") {

    // Check if 'target' has a defined 'element' property AND if that 'element' has a defined 'uri' property.
    // The use of "!!" is to coerce the values to booleans, effectively checking if they exist/truthy.
    if (!!target.element && !!target.element?.uri) {

      // Using the 'getHref' function to generate a URL, based on the 'target.element' and 'baseUrl' provided.
      let href = getHref(target.element, baseUrl);

      // If 'href' is not truthy (null, undefined, etc.), then invoke the 'returnElement' function and exit from the current block.
      if (!href) return returnElement();

      // Initialize a variable 'newTab' which will later determine if the link should open in a new tab or the current one.
      let newTab;

      // Check if the current route (router.pathname) is "/partner-portal/[slug]" AND if the 'sectionHandle' property of 'target.element' is "partnerPortal".
      if (
        router.pathname === "/partner-portal/[slug]" &&
        target.element.sectionHandle === "partnerPortal"
      ) {
        // If both conditions are met, the link should NOT open in a new tab.
        newTab = false;

        // Additionally, if 'downloadableFiles' property (which is an array) of 'target.element' has at least one item, 
        // set 'href' to the 'url' of the first file and set 'newTab' to true so the link will open in a new tab.
        if (target.element.downloadableFiles && target.element.downloadableFiles[0]) {
          href = target.element.downloadableFiles[0].url;
          newTab = true;
        }
      } else if (router.pathname === "/partner-portal/[slug]") {
        // If only the route matches but not the 'sectionHandle', then the link should open in a new tab.
        newTab = true;
      }

      // Return a 'Link' component (typically used in frameworks like Next.js for client-side navigation).
      // The 'Link' will have various properties set based on the logic above and will wrap the 'children' content.
      return (
        <Link
          href={href}
          passHref={true}
          className={className}
          style={style}
          draggable={false}
          target={newTab ? "_blank" : null}>

          {children}

        </Link>
      );
    } 
    // If the above condition fails (i.e., 'target.element' or 'target.element.uri' doesn't exist), 
    // but 'target' has a defined 'url' property, then this block will execute.
    else if (!!target.url) {

      // Return a standard anchor ('a') element with various properties set.
      // This anchor will open the URL specified in 'target.url' and will wrap the 'children' content.
      return (
        <a
          href={target.url}
          className={className}
          rel="noreferrer"
          draggable={false}
          style={style}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={handleClick}
        >
          {children}
        </a>
      );
    }
  }


  if (target.type === "asset" && (!!target.element || !!target.url)) {
    return (
      <a
        href={target.element?.url ? target.element.url : target.url}
        className={className}
        draggable={false}
        download={download}
        target="_blank"
        rel="noreferrer"
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleClick}
      >
        {children}
      </a>
    );
  }

  if (target.type === "url") {
    return (
      <a
        href={target.url}
        className={className}
        target="_blank"
        rel="noreferrer"
        draggable={false}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleClick}
      >
        {children}
      </a>
    );
  }

  if (target.type === "email") {
    return (
      <a
        href={target.url}
        className={className}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleClick}
      >
        {children}
      </a>
    );
  }

  if (target.type === "custom") {
    return (
      <a
        href={target.url}
        className={className}
        draggable={false}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleClick}
      >
        {children}
      </a>
    );
  }

  return returnElement();
};

export default Target;
