import clsx from "clsx";

const Logo = () => {
  return (
    <svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.5033 0.00170898L43.3717 43.3752H35.57L21.6868 0.00170898H29.5033ZM1.14441e-05 43.3752H7.80353L21.6868 0.00170898H13.8647L1.14441e-05 43.3752Z"
        fill="currentColor"
        className={clsx("transition-colors")}
      />
    </svg>
  );
};

export default Logo;
