import { initPlasmicLoader } from "@plasmicapp/loader-nextjs";

export const PLASMIC = initPlasmicLoader({
	projects: [
		{
			id: process.env.NEXT_PUBLIC_PLASMIC_DESIGN_SYSTEM_PROJECT_ID, // ID of a project you are using
			token: process.env.NEXT_PUBLIC_PLASMIC_DESIGN_SYSTEM_PROJECT_TOKEN, // API token for that project
		},
	],

	// By default Plasmic will use the last published version of your project.
	// For development, you can set preview to true, which will use the unpublished
	// project, allowing you to see your designs without publishing.  Please
	// only use this for development, as this is significantly slower.
  preview:
    process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" ||
		process.env.VERCEL_ENV === "preview" ||
		process.env.NODE_ENV !== "production",
});
