import { Box, Button, Flex, Input } from "@chakra-ui/react";
import { ArrowRight, Search } from "@components/Icons";
interface IProps {
	getAutocomplete: () => JSX.Element;
	getButtonProps: (additionalProps?: any) => any;
	getInputProps: (additionalProps?: any) => any;
}

const SiteSearchInput = ({
	getAutocomplete,
	getInputProps,
	getButtonProps,
}: IProps) => {
	const { className, isSubmitting } = getInputProps();
	const isHeaderSearch = className.includes("header-search");
	return (
		<Flex
			width="100%"
			bg={isHeaderSearch ? "#FFFFFF" : null}
			justifyContent="center"
			alignItems="center"
			gap={!isHeaderSearch && "24px"}
			height="50px"
		>
			<Box className="sui-search-box__wrapper" width="100%" height="100%">
				<Input
					autoFocus
					width="100%"
					height="100%"
					py="0"
					fontSize={"20px"}
					{...getInputProps({
						placeholder: "What are you looking for?",
					})}
					isDisabled={isSubmitting}
				/>
				{getAutocomplete()}
			</Box>
			{isHeaderSearch ? (
				<Button
					{...getButtonProps({
						"data-custom-attr": "some value",
					})}
					type="submit"
					w="20"
					h="34px"
					borderLeft="1px solid #BBBCBC"
					display={{ base: "none", sm: "flex" }}
					justifyContent="center"
					alignItems="center"
          background="none"
          stroke="black-01"
					isLoading={isSubmitting}
					_hover={{ background: "rgba(0,0,0,0.01)" }}
					_active={{ background: "none" }}
				>
					<Search />
				</Button>
			) : (
				<Button
					width="115px"
					height="100%"
					type="submit"
					backgroundColor="#B2F5E2"
					color="#000000"
					rightIcon={<ArrowRight />}
					isLoading={isSubmitting}
					_hover={{
						bg: "#0D1418",
						color: "#FFFFFF",
						".chakra-button__icon": {
							transform: "translateX(.25rem)",
						},
					}}
				>
					Search
				</Button>
			)}
		</Flex>
	);
};

export default SiteSearchInput;
