import { atom } from "jotai";

export const isHeaderFixedAtom = atom(true);

export const isHeaderPinnedAtom = atom(false);

export const isSiteMenuActiveAtom = atom(false);

export const headerThemeAtom = atom("dark");

export const activeNavItemIdAtom = atom("");

export const isSiteMobileMenuActiveAtom = atom(false);

export const isSearchSubmittingAtom = atom(false)

export const headerHeight = atom(80);
