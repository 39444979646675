import { useRouter } from "next/router";
import React, { useCallback, useState } from "react";

import { Flex } from "@chakra-ui/react";
import SiteSearchAutocomplete from "./SiteSearchAutocomplete";
import SiteSearchInput from "./SiteSearchInput";

import {
  isSearchSubmittingAtom,
  isSiteMenuActiveAtom,
  isSiteMobileMenuActiveAtom,
} from "@atoms/siteHeader";
import { ErrorBoundary, SearchBox } from "@elastic/react-search-ui";
import { useAtom } from "jotai";

interface IProps {
	onClose: () => any;
}

export default function SiteSearch({ onClose }: IProps) {
	const [isSubmitting, setIsSubmitting] = useAtom(isSearchSubmittingAtom);
	const [isSiteMobileMenuActive, setIsSiteMobileMenuActive] = useAtom(
		isSiteMobileMenuActiveAtom
	);
	const [isSiteMenuActive, setIsSiteMenuActive] = useAtom(isSiteMenuActiveAtom);

	const router = useRouter();

	const searchRef = useCallback((node) => {
		if (node !== null) {
      const form = node.querySelector("form");
      if (form?.style) {        
        form.style.maxWidth = "727px";
        form.style.width = "100%";
      }
		}
	}, []);

	return (
		<Flex
			ref={searchRef}
			className="SiteSearch"
			maxWidth="727px"
			marginLeft={{ base: "70px", md: "190px" }}
			width="100%"
		>
			<ErrorBoundary>
				<SearchBox
					inputProps={{
						className: "header-search",
						isSubmitting: isSubmitting,
					}}
					inputView={SiteSearchInput}
					autocompleteView={SiteSearchAutocomplete}
					autocompleteMinimumCharacters={3}
					autocompleteResults={{
						linkTarget: "_blank",
						sectionTitle: "Results",
						titleField: "title",
						urlField: "url",
						shouldTrackClickThrough: true,
					}}
					autocompleteSuggestions={true}
					onSubmit={async (searchTerm) => {
						if (!!searchTerm) {
							setIsSubmitting(true);
							onClose();
							setIsSiteMobileMenuActive(false);
							setIsSiteMenuActive(false);
							await router.push({
								pathname: "/results",
								query: { q: searchTerm },
							});

							setIsSubmitting(false);
						}
					}}
					debounceLength={0}
				/>
			</ErrorBoundary>
		</Flex>
	);
}
