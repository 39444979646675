import Connector from "../../services/APIConnector"

const connector = new Connector();

const query = {
	body: {},
	external_id: {},
	image: {},
	info: {},
	popularity: {},
	published_at: {},
	sections: {},
	title: {},
	type: {},
	updated_at: {},
	url: {},
};

const postQuery = {
	bodyText: {},
	external_id: {},
	category: {},
	image: {},
	id: {},
	postDate: {},
	summary: {},
	title: {},
	updated_at: {},
	uri: {},
};

const config = (queryType, queryFilter) => {
	const isPost = (queryType === "blog") || (queryType ===  "resources")
	return {
		debug: process.env.NODE_ENV !== "production",
		apiConnector: connector,
		hasA11yNotifications: true,
		initialState: {resultsPerPage: 0},
		searchQuery: {
			resultsPerPage: 20,
			result_fields: isPost ? postQuery : query,
			queryType: queryType,
			filters: [
				{
					field: "url",
					values: queryFilter
				}
			],

		},
		autocompleteQuery: {
			results: {
				resultsPerPage: 5,
				result_fields: {},
				filters: [
				{
					field: "url",
					values: queryFilter
				}
			],
			},
			
		},
	}
};

export default config