import "@styles/app.css";
import "@styles/globals.css";
import theme from "chakra.theme";
import "intersection-observer";

import clsx from "clsx";
import { Provider as JotaiProvider } from "jotai";
import { NextComponentType, NextPageContext } from "next";
import Head from "next/head";
import { useRouter } from "next/router";

import NProgress from "nprogress";
import React, { useEffect, useState } from "react";

import Layout from "@layouts/Layout";
import modifyUtms from "@lib/modifyUtms";
import cookieCutter from "cookie-cutter";
import Script from "next/script";

import SiteHeader from "@modules/SiteHeader";
import SiteMenu from "@modules/SiteMenu";
import SiteMobileMenu from "@modules/SiteMobileMenu";

import { PLASMIC } from "@abnormal/plasmic-init-design-system";
import { Box, ChakraProvider, Flex } from "@chakra-ui/react";
import TopBar from "@modules/TopBar";
import {
  PlasmicComponent,
  PlasmicRootProvider
} from "@plasmicapp/loader-nextjs";

NProgress.configure({ easing: "easeOut", speed: 750 });

declare global {
	interface Window {
		dataLayer: any;
	}
}

type AppProps = {
	pageProps: any;
	err: any;
	Component: NextComponentType<NextPageContext, any, {}> & { Layout: any };
};

function MyApp({ Component, pageProps, err }: AppProps): JSX.Element {
	const [isLoaded, setIsLoaded] = useState(false);
	const TargetLayout = Component.Layout ? Component.Layout : Layout;
	const [showMobileMenu, setShowMobileMenu] = useState(false);

	const router = useRouter();

  // if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" || process.env.NODE_ENV === "development") {
  //   console.log(pageProps);
  // }

	const [plasmic, setPlasmic] = useState(false);

	let hideHeaderFooter = false;
	if (
		pageProps?.pageEntry?.sectionHandle === "partnerPortal" ||
		!!pageProps.content
	) {
		hideHeaderFooter = true;
	}

	useEffect(() => {
		setIsLoaded(true);
	}, []);

  /* Cookie Handling */
  useEffect(() => {
    /* Get new URL parameters (including UTMs) */
    const newUrlParams = router.query;

    /* If there are new URL parameters, process and then store them in cookies */
    if (Object.keys(newUrlParams).length) {
      /* Set cookie expiration dates */
      const expireDateShort = new Date(); /* Short expiration, used for non-UTM params */
      expireDateShort.setDate(expireDateShort.getDate() + 1);
      const expireDateLong = new Date(); /* Long expiration, used for UTMs */
      expireDateLong.setDate(expireDateLong.getDate() + 60);

      /* Get currently-stored URL and UTM params */
      const storedUrlParams = cookieCutter.get("urlParams") ? JSON.parse(cookieCutter.get("urlParams")) : {};
      const storedUtms = cookieCutter.get("utmParams") ? JSON.parse(cookieCutter.get("utmParams")) : {};
      
      /* Check if newUrlParams contains UTMs */
      let utms = ["utm_medium", "utm_source", "utm_campaign", "utm_content", "utm_term"];
      let hasNewUtms = utms.some((key) => {
        return key in newUrlParams;
      });
      /* If no UTMs are present in the URL, check if we can dynamically generate them */
      if (!hasNewUtms && document?.referrer) {
        /* Check if the referring URL SLD matches criteria to dynamically generate new UTMs */
        /* Currently this is just for search engines, but could be expanded to include other sources */
        const searchEngines = ["google", "bing", "yahoo", "duckduckgo", "baidu", "yandex", "naver"];
        const referrerHostname = new URL(document.referrer).hostname;
        const referrerHostnameParts = referrerHostname.split(".");
        // const referrerHostnameTLD = referrerHostnameParts[referrerHostnameParts.length - 1]; /* e.g. "com" */
        const referrerHostnameSLD = referrerHostnameParts[referrerHostnameParts.length - 2]; /* e.g. "google" */
        if (searchEngines.includes(referrerHostnameSLD)) {
          newUrlParams["utm_medium"] = "Organic Website";
          newUrlParams["utm_source"] = referrerHostnameSLD; /* Will be standardized via the modifyUtms function */
          hasNewUtms = true;
        }
      }
      /* If UTMs are present in the URL or we dynamically generated them... */
      const newUtms = {};
      if (hasNewUtms) {
        /* Clear all UTMs from storedUtms (and storedUrlParams for backwards compatibility) */
        utms.forEach((key) => {
          delete storedUtms[key];
          delete storedUrlParams[key];
        });
        /* Add the utm_datetime (set to current timestamp) to the newUtms */
        newUtms["utm_datetime__c"] = new Date().toISOString();
      }
      /* Add the "extended UTM params" (gclid, etc.) to list of items to store in newUtms (does not require hasNewUtms to be true) */
      utms = [...utms, "gclid", "GCLID__c", "msclkid", "fbclid"];
      /* Strip the UTMs out of newUrlParams and store in newUtms */
      utms.forEach((key) => {
        if (key in newUrlParams) {
          newUtms[key] = newUrlParams[key];
          delete newUrlParams[key];
        }
      });

      /* Build new cookie objects */
      const urlParamsCookie = {
        ...storedUrlParams,
        ...newUrlParams
      };
      const utmParamsCookie = {
        ...storedUtms,
        ...newUtms,
      };

      /* Store new cookies */
      if (Object.keys(urlParamsCookie).length) {
        /* Store URL params */
        cookieCutter.set("urlParams", JSON.stringify(urlParamsCookie), {
          expires: expireDateShort, // Non-UTM params stored for 1 day
        });
      }
      if (Object.keys(utmParamsCookie).length) {
        /* Modify UTMs to match Salesforce field names */
        const modifiedUtms = modifyUtms(utmParamsCookie);

        /* Store modified UTMs */
        cookieCutter.set("utmParams", JSON.stringify(modifiedUtms), {
          expires: expireDateLong, // UTM params stored for 60 days
        });
      }
    }
  }, [router.query]);

  /* Page Navigation */
  useEffect(() => {
    /* Progress bar for page navigation */
    const routeChangeStart = () => {
      NProgress.start();
    };
    const routeChangeEnd = () => {
      NProgress.done();
    };
    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeComplete", routeChangeEnd);
    router.events.on("routeChangeError", routeChangeEnd);

    // /* Google Tag Manager page view events */
    // const handleRouteChange = (url) => {
    //   window.dataLayer = window.dataLayer || [];
    //   window.dataLayer.push({
    //     event: 'page_view',
    //     page: url,
    //   })
    // }
    // router.events.on('routeChangeComplete', handleRouteChange)
    // return () => {
    //   router.events.off('routeChangeComplete', handleRouteChange)
    // }
	}, [router.events]);

	const showTopBar =
    !!pageProps.persistentNav && pageProps.persistentNav.showOnAllPages;
  
  // Set header theme
  const headerTheme = pageProps.landingPageEntry?.headerTheme
    || pageProps?.headerTheme
    || pageProps.pageEntry?.headerTheme
    || null;
  
	if (plasmic) {
		return <Component {...pageProps} setPlasmic={setPlasmic} />;
	}

	return (
		<JotaiProvider>
			<Head>
				<meta charSet="utf-8" />
				<meta httpEquiv="x-ua-compatible" content="ie=edge" />
				<meta
					name="viewport"
					content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0"
				/>
				<meta name="format-detection" content="telephone=no" />

				<link rel="preconnect" href="https://cmp.osano.com" />

				<meta charSet="utf-8" />

				<link
					rel="sitemap"
					type="application/xml"
					title="Sitemap"
					href="/sitemaps-1-sitemap.xml"
				/>

        {/* Favicon */}
				<link rel="apple-touch-icon-precomposed" sizes="57x57" href="/apple-touch-icon-57x57.png" />
				<link rel="apple-touch-icon-precomposed" sizes="114x114" href="/apple-touch-icon-114x114.png" />
				<link rel="apple-touch-icon-precomposed" sizes="72x72" href="/apple-touch-icon-72x72.png" />
				<link rel="apple-touch-icon-precomposed" sizes="144x144" href="/apple-touch-icon-144x144.png" />
				<link rel="apple-touch-icon-precomposed" sizes="60x60" href="/apple-touch-icon-60x60.png" />
				<link rel="apple-touch-icon-precomposed" sizes="120x120" href="/apple-touch-icon-120x120.png" />
				<link rel="apple-touch-icon-precomposed" sizes="76x76" href="/apple-touch-icon-76x76.png" />
				<link rel="apple-touch-icon-precomposed" sizes="152x152" href="/apple-touch-icon-152x152.png" />
				
        <link rel="icon" type="image/png" sizes="196x196" href="/favicon-196x196.png" />
				<link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="icon" type="image/png" sizes="128x128" href="/favicon-128.png" />
        
				<meta name="application-name" content="&nbsp;" />
				<meta name="msapplication-TileColor" content="#FFFFFF" />
				<meta name="msapplication-TileImage" content="/mstile-144x144.png" />
				<meta name="msapplication-square70x70logo" content="/mstile-70x70.png" />
				<meta name="msapplication-square150x150logo" content="/mstile-150x150.png" />
				<meta name="msapplication-wide310x150logo" content="/mstile-310x150.png" />
				<meta name="msapplication-square310x310logo" content="/mstile-310x310.png" />

				<meta name="apple-mobile-web-app-title" content="Abnormal Security" />
				<meta name="application-name" content="Abnormal" />
				<meta name="apple-mobile-web-app-status-bar-style" content="white" />
				<meta name="format-detection" content="telephone=no" />
			</Head>

      {(process.env.NEXT_PUBLIC_GTM_ID) && (
        <>
          {/* Consent Managers (Google Consent Manager 2.0, Osano) are located in /_document.tsx */}

          {/* Data Layer */}
          <Script id="datalayer" strategy="afterInteractive">
            {
              // If there are external IDs, iterate through the object and set each as a dimension
              pageProps.externalIds &&
              Object.keys(pageProps.externalIds).length
              ? `gtag('set', {${Object.keys(pageProps.externalIds)
                  .map((key) => `'ab_${key.toLowerCase()}': '${pageProps.externalIds[key]}'`)
                  .join(",")}});`
              : ""
            }
          </Script>

          {/* Google Tag Manager */}
					<Script id="gtm" strategy="afterInteractive">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`}
					</Script>
          
					<Script id="gtm" strategy="lazyOnload">
						{`var clientId = ga.getAll()[0].get('clientId');
							ga('send', {
							hitType: 'event',
							eventCategory: 'Client ID',
							eventAction: Set,
							dimension15: clientId
							});`}
					</Script>
				</>
			)}

      {/* Site Search - Swiftype */}
			<Script id="site-search" strategy="lazyOnload">
				{`(function(w,d,t,u,n,s,e){w['SwiftypeObject']=n;w[n]=w[n]||function(){
						(w[n].q=w[n].q||[]).push(arguments);};s=d.createElement(t);
						e=d.getElementsByTagName(t)[0];s.async=1;s.src=u;e.parentNode.insertBefore(s,e);
						})(window,document,'script','//s.swiftypecdn.com/install/v2/st.js','_st');
						
						_st('install','MPCTbKVvgW6arYio-yHr','2.0.0');`}
			</Script>

      {/* Outgrow Chatbot Script */}
			<Script id="outgrow-chat" strategy="lazyOnload">
				{`var script = document.createElement('script');
					script.src = 'https://dyv6f9ner1ir9.cloudfront.net/assets/js/chatV2.js';
					document.getElementsByTagName('body')[0].appendChild(script);`}
			</Script>
			{/* Outgrow Chat Stylesheets - see app.css for actual imports
				<link href="https://dyv6f9ner1ir9.cloudfront.net/assets/css/shared/chatV2.css" rel="stylesheet" />
				<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
			*/}

			<ChakraProvider theme={theme}>
				{/* Start Outgrow Chatbot */}
				<a href="" style={{ backgroundColor: "#000000" }} className="bot-circle right" id="bot-circle" onClick={(event) => {
					event.preventDefault();
					ogAnimationInit();
				}}>
					<div className="bot-circle-icon-open">
						<i style={{color: "#ffffff"}} className="material-icons">chat</i>
					</div>
					<div className="bot-circle-icon-close">
						<i style={{color: "#ffffff"}} className="material-icons">expand_more</i>
					</div>
					{/* <div className="og-chat-tooltip">
						<p>What&apos;s your future like?</p>
						<span className="og-chat-cross" onClick={(event) => ogAnimationTextremoveInit(event)}>
							<i className="material-icons">clear</i>
						</span>
					</div> */}
				</a>
				<div className="og-chat-box-outer">
					<div className="og-chat-box no-animation" id="og-chat-box">
						<div className="og-chat-box-top" style={{backgroundColor: "#000000"}}>
							<a href="" className="bot-circle-mobile" id="bot-circle-mobile" onClick={(event) => {
								event.preventDefault();
								ogAnimationClose();
							}}>
								<div className="bot-circle-icon-close">
									<i style={{color: "#ffffff"}} className="material-icons">
										<span style={{ display: "none" }}>close</span>
									</i>
								</div>
							</a>
						</div>
						<iframe title="Website Chatbot" src="https://abnormalsecurity.outgrow.us/64e37afea593954b6e60f08f" allow="camera *;" width="100%" height="100% ">
						</iframe>
					</div>
				</div>
				{/* End Outgrow Chatbot */}
				<Flex className="flex flex-col justify-between relative" minH={"100vh"}>
					{showTopBar && <TopBar persistentNav={pageProps.persistentNav} />}
					<Box className="pageContent" pos="relative">
						{!hideHeaderFooter && (
							<SiteHeader
								sectionNav={pageProps.pageEntry?.sectionNavigation}
								navigationItems={
									pageProps.landingPageEntry?.navigationLinks
										? pageProps.landingPageEntry?.navigationLinks
										: pageProps.navigation?.primaryNavigation
								}
								callToActions={pageProps.navigation?.primaryCallToActions}
								landingPageCallToActions={
									pageProps.landingPageEntry?.target?.url
										? {
												target: pageProps.landingPageEntry?.target,
												textColor: pageProps.landingPageEntry?.buttonTextColor,
												backgroundColor:
													pageProps.landingPageEntry?.buttonBackgroundColor,
										  }
										: false
								}
								logo={
									pageProps.landingPageEntry?.logo &&
									pageProps.landingPageEntry?.logo
								}
								isLandingPage={pageProps.landingPageEntry ? true : false}
                headerTheme={headerTheme}
								isTopBar={showTopBar}
							/>
						)}

						{!pageProps.landingPageEntry?.navigationLinks && (
							<SiteMenu items={pageProps.navigation?.primaryNavigation} />
						)}

						<TargetLayout {...pageProps}>
							<Box className="PageContent">
								<Component {...pageProps} err={err} setPlasmic={setPlasmic} />
							</Box>
						</TargetLayout>

						{isLoaded && pageProps.plasmicData &&
							!hideHeaderFooter &&
							!pageProps.landingPageEntry?.hideFooter && !pageProps.pageEntry?.hideFooter && (
								<PlasmicRootProvider
									loader={PLASMIC}
									prefetchedData={pageProps.plasmicData}
								>
                  <PlasmicComponent component="Footer" />
								</PlasmicRootProvider>
							)}
					</Box>
				</Flex>
				{!hideHeaderFooter && (
					<div
						className={clsx(`${showMobileMenu ? "block" : "hidden"} xl:hidden`)}
					>
						<SiteMobileMenu
							items={
								pageProps.landingPageEntry?.navigationLinks
									? pageProps.landingPageEntry?.navigationLinks
									: pageProps.navigation?.primaryNavigation
							}
							callToActions={pageProps.navigation?.primaryCallToActions}
							setShowMobileMenu={setShowMobileMenu}
						/>
					</div>
				)}
			</ChakraProvider>
		</JotaiProvider>
	);
}
export default MyApp;
