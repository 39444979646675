/*
Breakpoint prefix	Minimum width	CSS
sm	641px	@media (min-width: 641px) { ... }
md	769px	@media (min-width: 769px) { ... }
lg	1025px	@media (min-width: 1025px) { ... }
xl	1241px	@media (min-width: 1241px) { ... }
2xl	1401px  @media (min-width: 1401px) { ... }
3xl	2101px  @media (min-width: 2101px) { ... }
*/

module.exports = {
	mode: "jit",
	content: [
		"./pages/**/*.{js,ts,jsx,tsx}",
		"./components/**/*.{js,ts,jsx,tsx}",
		"./layouts/**/*.{js,ts,jsx,tsx}",
		"./modules/**/*.{js,ts,jsx,tsx}",
	],
	safelist: [
		"text-xs text-sm text-base text-md md:text-xl text-lg md:text-2xl text-xl md:text-3xl text-2xl md:text-4xl text-3xl md:text-5xl text-4xl md:text-6xl text-purple-01 text-purple-warm-04 text-violet-01 text-violet-04 text-green-02 text-green-03 text-green-05 justify-normal justify-stretch justify-start justify-end justify-between justify-center justify-around md:justify-center align-normal align-stretch align-start align-end align-between align-center align-around max-w-full sm:inline md:inline bottom-4 bottom-6 bottom-8 bottom-10",
	],
	theme: {
		container: {
			center: true,
		},
		fontSize: {
			xs: ".75rem", // 12px
			sm: ".875rem",
			// 'tiny': '.875rem',
			base: ["1rem", 1.5], // 16px
			// 'lg': '1.125rem',
			xl: ["1.25rem", 1.4],
			"2xl": ["1.5rem", 1.333], // 24px
			"3xl": ["2rem", 1.25], // 32px; original 1.875rem
			"4xl": ["2.25rem", 1.2], // 52px; original 2.25rem
			"5xl": ["3.25rem", "1.15384615"], // 52px; original 3rem
			"6xl": ["3.75rem", "1.0666"],
			"7xl": "4.5rem",
			"8xl": ["6rem", 1], // 96px
			"9xl": ["7.5rem", 1], // 120px; original 8rem
		},
		colors: {
			"white-01": "#ffffff",
			"white-02": "#F7f7F7",
			"white-03": "#E5E7E7",
			"violet-01": "#6863F2",
			"violet-02": "#B9BAED",
			"violet-04": "#924E8C",
			"black-01": "#0D1418",
			"black-02": "#0D1418",
			"beige-01": "#D7C088",
			"beige-02": "#FDF1D2",
			"beige-03": "#FDF1D2",
			"beige-04": "#F8F9F2",
			"green-01": "#3D8280",
			"green-02": "#5EC28F",
			"green-03": "#A7DED2",
			"green-05": "#B2F5E2",
			"mint-01": "#B2F5E2",
			"green-06": "#DFF3F1",
			"blue-01": "#284A74",
			"blue-02": "#58B2F6",
			"blue-03": "#AFEEEE",
			"blue-05": "#DDECF4",
			"teal-01": "#204753",
      "gray-01": "#919191",
			"purple-01": "#6863F2",
      "purple-02": "#483698",
      "purple-03": "#B9BAED",
      "purple-warm-04": "#924E8C",
      "purple-warm-05": "#692764ff",
			"purple-gray-01": "#F6F6F9",
			"purple-gray-02": "#EEEEF9",
			"purple-gray-03": "#CECEDC",
			"purple-dark-05": "#241544",
			red: "#EE4852",
			transparent: "transparent",
			current: "currentColor",
		},
		fontFamily: {
			sans: [
				"TWK Everett",
				"ui-sans-serif",
				"system-ui",
				"-apple-system",
				"BlinkMacSystemFont",
				"Segoe UI",
				"Roboto",
				"Helvetica Neue",
				"Arial",
				"Noto Sans",
				"sans-serif",
				"Apple Color Emoji",
				"Segoe UI Emoji",
				"Segoe UI Symbol",
				"Noto Color Emoji",
			],
		},
		extend: {
      spacing: {
        0.5: '0.125rem', // 2px
        1: '0.25rem', // 4px
        1.5: '0.375rem', // 6px
        2: '0.5rem', // 8px
        2.5: '0.625rem', // 10px
        3: '0.75rem', // 12px
        3.5: '0.875rem', // 14px
        4: '1rem', // 16px
        5: '1.25rem', // 20px
        5.5: '1.375rem', // 22px
        6: '1.5rem', // 24px
        6.5: '1.625rem', // 26px
        7: '1.75rem', // 28px
        7.5: '1.875rem', // 30px
        8: '2rem', // 32px
        9: '2.25rem', // 36px
        10: '2.5rem', // 40px
        12: '3rem', // 48px
        14: '3.5rem', // 56px
        15: '3.75rem', // 60px
        16: '4rem', // 64px
        20: '5rem', // 80px
        23: '5.625rem', // 90px
        24: '6rem', // 96px
        28: '7rem', // 112px
        30: '7.5rem', // 120px
        32: '8rem', // 128px
        35: '8.75rem', // 140px
        36: '9rem', // 144px
        40: '10rem', // 160px
        44: '11rem', // 176px
        48: '12rem', // 192px
        52: '13rem', // 208px
        56: '14rem', // 224px
        60: '15rem', // 240px
        64: '16rem', // 256px
        72: '18rem', // 288px
        80: '20rem', // 320px
        90: '22.5rem', // 360px
        95: '23.75rem', // 380px
        96: '24rem', // 384px
        100: '25rem', // 400px
        128: '32rem', // 512px
        250: "62.5rem", // 1000px,
        256: "64rem", // 1024px
      },
      lineHeight: {
				15: "3.75rem",
			},
			transitionProperty: {
				height: "height",
				spacing: "margin, padding",
			},
      screens: {
        sm: "641px",
        md: "769px",
        lg: "1025px",
				xl: "1241px",
				half: "600px",
				"2xl": "1401px",
				"3xl": "2101px",
			},
			boxShadow: {
				nav: "inset 0 7px 6px -7px rgb(0 0 0 / 0.15);",
			},
			typography: {
				DEFAULT: {
					css: {
						color: "currentColor",
						a: {
							fontWeight: "inherit",
						},
						figure: {
							marginBottom: "1em",
						},
						h3: {
							color: "currentColor",
							marginTop: 0,
							fontWeight: "inherit",
						},
						h2: {
							color: "currentColor",
							marginTop: 0,
							fontWeight: "inherit",
						},
						strong: {
							color: "inherit",
						},
						ul: {
							paddingLeft: "24px",
							li: {
								marginTop: "4px",
								marginBottom: "4px",
								paddingLeft: "8px",
								"&::before": {
									backgroundColor: "currentColor",
								},
								"&::marker": {
									color: "inherit !important",
								},
							},
						},
						ol: {
							paddingLeft: "24px",
							li: {
								marginTop: "4px",
								marginBottom: "4px",
								paddingLeft: "8px",
								"&::before": {
									backgroundColor: "currentColor",
								},
								"&::marker": {
									color: "inherit !important",
								},
							},
						},
						//fix paragraph nested in li
						li: {
							p: {
								marginTop: 0,
								marginBottom: 0,
							},
						},
					},
				},
			},
		},
	},
	plugins: [
		require("@tailwindcss/typography"),
		require("@tailwindcss/aspect-ratio"),
		require("@tailwindcss/forms")({
			strategy: "class",
		}),
	],
};
