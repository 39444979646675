import clsx from "clsx";
import { ReactNode } from "react";

import { NavigationItem } from "@interfaces/NavigationItem.interface";

interface IProps {
	navigation: {
		primaryNavigation: NavigationItem[];
		primaryCallToActions: NavigationItem[];
		footerPrimaryNavigation: NavigationItem[];
		footerSecondaryNavigation: NavigationItem[];
	};
	children: ReactNode;
	backgroundColor?: string;
}

const Layout = ({
	children,
	backgroundColor = "#F6F6F9",
}: IProps): JSX.Element => {
	return (
		<main
			id="content"
			className={clsx("mb-auto")}
			style={{ backgroundColor: backgroundColor }}
		>
			{children}
		</main>
	);
};

export default Layout;
